import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Card, CardContent, FormControlLabel, Checkbox, Grid } from '@mui/material';
import axios from 'axios';
import Menu from './Menu';
import TopBar from './TopBar';
import Footer from './Footer';
import './DarkWebMonitoring.css';
import { exportToCsv } from './exportToCsv';

interface SnusbaseResult {
  results: {
    [key: string]: Array<{
      email?: string;
      username?: string;
      password?: string;
      hash?: string;
      name?: string;
      lastip?: string;
    }>;
  };
  errors?: Array<{ message: string }>;
}

const SNUSBASE_API_KEY = 'sb0lfm1125dua65l7r43zsr9u88ssv'; // Replace with your Snusbase API key

const DarkWebMonitoring: React.FC = () => {
  const [queryParams, setQueryParams] = useState({
    email: '',
    username: '',
    password: '',
    hash: '',
    name: '',
    lastip: '',
    domain: '',
  });
  const [wildcard, setWildcard] = useState(false);
  const [snusbaseResult, setSnusbaseResult] = useState<SnusbaseResult | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleInputChange = (key: keyof typeof queryParams, value: string) => {
    setQueryParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearchSnusbase = async () => {
    try {
      const terms: string[] = [];
      const types: string[] = [];

      // Build the search criteria dynamically
      Object.entries(queryParams).forEach(([key, value]) => {
        if (value) {
          terms.push(value);
          // Map "domain" to "_domain" for the API query
          types.push(key === 'domain' ? '_domain' : key);
        }
      });

      if (terms.length === 0) {
        console.error('At least one search criterion is required.');
        return;
      }

      const response = await axios.post('https://api.snusbase.com/data/search', {
        terms,
        types,
        wildcard,
      }, {
        headers: {
          'Auth': SNUSBASE_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.data && response.data.results) {
        setSnusbaseResult(response.data);
      } else if (response.data && response.data.errors) {
        console.error('API Error:', response.data.errors);
        alert(`Error: ${response.data.errors[0].message || 'An error occurred'}`);
        setSnusbaseResult(null);
      } else {
        console.error('Unexpected API response:', response.data);
        alert('Unexpected API response. Please try again later.');
        setSnusbaseResult(null);
      }
    } catch (error) {
      console.error('Error fetching Snusbase data:', error);
      alert('An error occurred while fetching data. Please try again.');
      setSnusbaseResult(null); // Reset state on error
    }
  };

  const handleExportToCsv = () => {
    if (!snusbaseResult || !snusbaseResult.results || !Object.keys(snusbaseResult.results).length) {
      console.error('No results to export');
      return;
    }

    const formattedResults: any[] = [];
    Object.keys(snusbaseResult.results).forEach((key) => {
      const entries = snusbaseResult.results[key];
      if (Array.isArray(entries)) {
        entries.forEach((entry) => {
          formattedResults.push({
            source: key,
            email: entry.email || '',
            username: entry.username || '',
            password: entry.password || '',
            hash: entry.hash || '',
            name: entry.name || '',
            lastip: entry.lastip || '',
          });
        });
      }
    });

    exportToCsv(formattedResults, 'darkweb_results.csv');
  };

  const generateSummary = () => {
    if (!snusbaseResult || !snusbaseResult.results) {
      return (
        <Typography variant="body1" style={{ color: '#fff' }}>
          No results available to summarize.
        </Typography>
      );
    }

    const summary = {
      email: 0,
      username: 0,
      password: 0,
      hash: 0,
      name: 0,
      lastip: 0,
    };

    Object.values(snusbaseResult.results).forEach((entries) => {
      if (Array.isArray(entries)) {
        entries.forEach((entry) => {
          Object.keys(summary).forEach((key) => {
            if (entry[key as keyof typeof summary]) {
              summary[key as keyof typeof summary]++;
            }
          });
        });
      }
    });

    return (
      <Box style={{ marginBottom: '20px', color: '#fff' }}>
        <Typography variant="body1">Metrics:</Typography>
        {Object.entries(summary).map(([key, count]) => (
          <Typography variant="body2" key={key}>
            {`${key.charAt(0).toUpperCase() + key.slice(1)}s: ${count}`}
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <Box
      className="dark-web-monitoring"
      style={{
        padding: '50px 20px', // Increased top padding for better spacing
        paddingBottom: '150px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 250px)',
      }}
    >
      <TopBar setDrawerOpen={setDrawerOpen} />
      <Menu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box style={{ marginBottom: '40px', marginTop: '60px' }}>
        <Typography variant="h4" gutterBottom>
          Dark Web Monitoring
        </Typography>
      </Box>
      <Box className="search-bar" style={{ marginBottom: '40px' }}>
        <Grid container spacing={3}>
          {Object.keys(queryParams).map((key) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <TextField
                label={key.charAt(0).toUpperCase() + key.slice(1)}
                value={queryParams[key as keyof typeof queryParams]}
                onChange={(e) => handleInputChange(key as keyof typeof queryParams, e.target.value)}
                fullWidth
                variant="outlined"
                className="search-input"
              />
            </Grid>
          ))}
        </Grid>
        <Box style={{ marginTop: '40px', textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchSnusbase}
            className="search-button"
          >
            Search
          </Button>
        </Box>
      </Box>
      <Box className="search-options" style={{ marginTop: '30px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={wildcard}
              onChange={(e) => setWildcard(e.target.checked)}
              color="primary"
            />
          }
          label="Enable Wildcard"
          className="wildcard-checkbox"
        />
      </Box>
      {snusbaseResult && (
        <Card className="results-card" style={{ marginTop: '40px' }}>
          <CardContent>
            <Typography variant="h6" sx={{ color: '#fff' }}>Summary of Results</Typography>
            {generateSummary()}
            <Box style={{ textAlign: 'right', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleExportToCsv}
              >
                Export to CSV
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      <Footer />
    </Box>
  );
};

export default DarkWebMonitoring;
